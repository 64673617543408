const cw = (cw || {});

(($) => {

	function Sharebtn() {

		let $link = $(".container_four-btn");
		let $share_element = $('#share-btn');
		const self = this;


		this.init = () => {
			$link.on("click", function () {
				$share_element.addClass("active-btn");
			});
		};

	}

	$(document).ready(() => {
		cw.sharebtn = new Sharebtn();
		cw.sharebtn.init();
	});

})(jQuery);