
const cw = (cw || {});

(($) => {

    function Contact() {

        const self = this;



        this.init = () => {
            self.registerEvents();
        };


        this.registerEvents = () => {
            $('.btn-contact').on('click', function () {
                $(".container_six").addClass("contact");
            });
        };


    }

    $(document).ready(() => {
        cw.contact = new  Contact();
        cw.contact.init();
    });

})(jQuery);