
const cw = (cw || {});

(($) => {

    function Mmenu() {

        const self = this;
        let $mmenu = $(".mobile-menu");

        this.init = () => {
            self.initMmenu()
        };

        this.initMmenu = () => {
            $mmenu.mmenu({
                "extensions": [

                    "position-front",
                    "theme-white",
                    "pageshadow",
                    "pagedim-black"
                ],

                onClick: {
                    close: true
                }

            });

            let API = $mmenu. data("mmenu");

            $("#my-button").click(function () {
                API.open();
            });

        }



    }

    $(document).ready(()=> {
        cw.mmenu = new Mmenu();
        cw.mmenu.init();
    });

})(jQuery);