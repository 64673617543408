
const cw = (cw || {});

(($) => {

    function Main() {

        const self = this;


        this.init = () => {
            self.registerEvents();

        };

        this.registerEvents = () => {


                $(".container_four-btn").on("click", function () {

                    $('#share-btn').addClass("active-btn")

                });


        };




    }

    $(document).ready(() => {
        cw.main = new Main();
        cw.main.init();
    });

})(jQuery);