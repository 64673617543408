const cw = (cw || {});

(($) => {

    function Footer() {
        const self = this;
        let $bimp = $("#bimp");
        let $bdsy = $(".btn-contact");

        let $impressum = $("#impressum");


        this.init = () => {

            self.autoOpenModal();

            /*$bimp.on("click", function () {
                if ($impressum.hasClass("impressum")) {
                    $impressum.removeClass("impressum");
                } else {
                    $impressum.addClass("impressum");
                    $("#data-safety").removeClass("data-safety");
                }
            });
            $bdsy.on("click", function () {
                if ($("#data-safety").hasClass("data-safety")) {
                    $("#data-safety").removeClass("data-safety");
                } else {
                    $("#data-safety").addClass("data-safety");
                    $("#impressum").removeClass("impressum");
                }
            });*/


            /*if (href.hasClass("#Modal-two")){

                $(window).on('open',function(){
                    $('#Modal-two').modal('show');
                });
            };*/
        };

        this.autoOpenModal = () => {
            let hash = window.location.hash.substr(1);
            if(!hash){ return; }
            let target = $('#' + hash);

            if(target.length){
                if(target.hasClass('modal')) {
                    target.modal('show');
                }
            }
        };
    }

    $(document).ready(() => {
        cw.footer = new Footer();
        cw.footer.init();

    });

})(jQuery);