const cw = (cw || {});

(($) => {

	function Scroll() {

		const self = this;
		let $navlist = $('.navigation_list');
		let $fullpage = $('#fullpage');
		let $siteWrapperScroll = $('.siteWrapper--scroll');
		let $header = $('header');
		let $headerImages = $('.header_image', $header).find('> div');
		let $scrollElements = $fullpage.children();
		let $scrollElementsMobile = $('.section--mobile', $siteWrapperScroll);
		let isMobile = window.matchMedia("(max-width:964px)");
		let scrollStepHeight = Math.floor($siteWrapperScroll.outerHeight());
		let currentIndex = 0;
		let scrollIndex = 0;
		let currentTextIndex = null;
		let isScrolling = false;
		let disableScrolingTime = 200;
		let touchStartY;
		let touchEndY;
		let textChangerActive = true;
		let firstTimestamp = $siteWrapperScroll.data('timestamp');
		let textSliderIntervalTime = 5000;

		this.init = () => {
			self.setScrollItemHeightMobile();
			self.registerEvents();
			self.addTouchScrollEvents();

			self.startTextSliders();
			self.updateHeader();

			if (isMobile.matches) {
				self.updateHeaderMobile($siteWrapperScroll.scrollTop());
				$(window).on("scroll", () => {
					self.updateHeaderMobile($siteWrapperScroll.scrollTop());
				});
			}
		};


		this.registerEvents = () => {
			$(window).on("resize", () => {
				setTimeout(() => {
					scrollStepHeight = Math.floor($siteWrapperScroll.outerHeight());

					self.setScrollItemHeightMobile();
					self.scrollToCurrentIndex();
				}, 100);
			});

			$('html,body').bind('wheel', (event) => {
				if (event.originalEvent.deltaY < 0) {
					self.scrollToPreviousElement();
					self.updateHeaderMobile($siteWrapperScroll.scrollTop());
				} else {
					self.scrollToNextElement();
					self.updateHeaderMobile($siteWrapperScroll.scrollTop());
				}
			});

			$('body').on('keydown', (event) => {
				if (event.keyCode === 40) { // arrow down
					self.scrollToNextElement();
					self.updateHeaderMobile($siteWrapperScroll.scrollTop());
				}

				if (event.keyCode === 38) { // arrow up
					self.scrollToPreviousElement();
					self.updateHeaderMobile($siteWrapperScroll.scrollTop());
				}
			});


			$navlist.find('a').click(function (e) {
				self.navScroll($(this).attr('href').substring(1));
				self.updateHeaderMobile($siteWrapperScroll.scrollTop());
			});

		};

		this.touchScrollEnd = () => {
			if(!touchStartY || !touchEndY){
				return false;
			}

			let touchDelta = Math.abs(touchEndY - touchStartY);

			if (touchDelta > 100) {
				if (touchEndY < touchStartY) {
					self.scrollToNextElement();
				} else {
					self.scrollToPreviousElement();
				}
			}
			touchStartY = null;
			touchEndY = null;
		};

		this.touchScrollStart = (e) => {
			if($(e.target).filter('a, button, .link').length){
				return false;
			}

			e.preventDefault(); // required to work in older android browsers

			let touch = e.changedTouches[0];
			touchStartY = touch.screenY;
		};

		this.touchScrollMove = (e) => {
			let touch = e.changedTouches[0];
			touchEndY = touch.screenY;
		};

		this.addTouchScrollEvents = () => {
			let scrollListenerItem1 = document.querySelector(".siteWrapper > .siteWrapper--scroll");
			let scrollListenerItem2 = document.querySelector("header > .header_image");

			scrollListenerItem1.addEventListener('touchend', this.touchScrollEnd, false);
			scrollListenerItem2.addEventListener('touchend', this.touchScrollEnd, false);

			scrollListenerItem1.addEventListener('touchstart', this.touchScrollStart, false);
			scrollListenerItem2.addEventListener('touchstart', this.touchScrollStart, false);

			scrollListenerItem1.addEventListener('touchmove', this.touchScrollMove, false);
			scrollListenerItem2.addEventListener('touchmove', this.touchScrollMove, false);
		};


		this.scrollToNextElement = () => {
			if (!isScrolling) {
				if (Math.ceil($fullpage.height() / $siteWrapperScroll.height()) - 1 > scrollIndex) {
					scrollIndex++;
					self.scrollToCurrentIndex();
				}
			}
		};

		this.scrollToPreviousElement = () => {
			if (!isScrolling) {
				if (0 < scrollIndex) {
					scrollIndex--;
					self.scrollToCurrentIndex();
				}
			}
		};

		this.scrollToCurrentIndex = () => {
			isScrolling = true;

			let scrollTop = scrollIndex * scrollStepHeight;
			let scrollDistance = scrollTop - $header.height();

			$siteWrapperScroll.stop().animate({
				scrollTop: scrollTop
			});

			this.updateHeaderMobile(scrollDistance);

			if(!textChangerActive){
				self.updateTextSlider();
			}

			setTimeout(() => {
				isScrolling = false;
			}, disableScrolingTime);
		};

		this.updateHeader = () => {
			$headerImages.stop().fadeOut(0);
			$headerImages.filter('.header_image-' + currentIndex).stop().fadeIn(0);

			if (isMobile.matches) {
				if($headerImages.filter('.header_image-' + currentIndex).hasClass("manual-mode") && textChangerActive){
					this.stopTextSliders();
				}

				if(!$headerImages.filter('.header_image-' + currentIndex).hasClass("manual-mode") && !textChangerActive){
					this.startTextSliders();
				}

				if(!$headerImages.filter('.header_image-' + currentIndex).hasClass("manual-mode") && textChangerActive){
					this.stopTextSliders();
					this.startTextSliders();
				}
			} else if(!textChangerActive){
				this.startTextSliders();
			} else {
				this.stopTextSliders();
				this.startTextSliders();
			}
		};

		this.updateHeaderMobile = () => {
			let scrollDistance = scrollIndex * $siteWrapperScroll.height();

			$scrollElements.each(function (key, item) {
				let $item = $(item);
				let topOffset = $item.offset().top - $fullpage.offset().top;
				let height = $item.height();
				let scrollDistanceNew = scrollDistance + ($siteWrapperScroll.height() / 2);

				if (scrollDistanceNew > topOffset && scrollDistanceNew < (topOffset + height)) {
					let index = $item.data('index');

					if (index !== currentIndex) {
						currentIndex = index;
						self.updateHeader();
					}
				}
			});
		};

		this.navScroll = (href) => {
			if (href.indexOf('#') === 0 && $(href).length) {
				let $hrefElement = $(href);
				let index = $hrefElement.data('index');
				let topOffset = $hrefElement.offset().top - $fullpage.offset().top - 1;

				currentIndex = index;
				scrollIndex = Math.round(topOffset / scrollStepHeight);
				self.scrollToCurrentIndex();
				self.updateHeader();

				return false;
			}
		};

		this.startTextSliders = () => {
			$headerImages.each((k, element) => {
				let $slideWrapper = $(element);
				if ($slideWrapper.hasClass('has-text-slider')) {
					$slideWrapper.children().fadeOut(0);
					$slideWrapper.children().first().fadeIn(0);
					$slideWrapper.data('index', 0);

					if(firstTimestamp && Date.now() - firstTimestamp > textSliderIntervalTime){
						this.textSliderChange($slideWrapper);
						firstTimestamp = null;
					}

					let interval = setInterval(() => {
						this.textSliderChange($slideWrapper);
					}, textSliderIntervalTime);
					$slideWrapper.data('interval', interval);
				}
			});
			textChangerActive = true;
		};

		this.textSliderChange = ($slideWrapper) => {
			let textIndex = $slideWrapper.data('index');
			textIndex = textIndex < $slideWrapper.children().length - 1 ? textIndex + 1 : 0;
			$slideWrapper.data('index', textIndex);

			$slideWrapper.children().stop().fadeOut(0);
			$slideWrapper.children().filter(':eq(' + textIndex + ')').stop().fadeIn(0);
		};

		this.stopTextSliders = () => {
			$headerImages.each((k, element) => {
				let $slideWrapper = $(element);
				if ($slideWrapper.hasClass('has-text-slider')) {
					let interval = $slideWrapper.data('interval');
					clearInterval(interval);
				}
			});
			textChangerActive = false;
		};

		this.setScrollItemHeightMobile = () => {
			$scrollElementsMobile.removeAttr('style');

			if (!isMobile.matches) {
				return false;
			}

			$scrollElementsMobile.attr('style', 'height:' + scrollStepHeight + 'px !important;');
		};

		this.updateTextSlider = () => {
			let scrollDistance = scrollIndex * $siteWrapperScroll.height();

			$scrollElementsMobile.each((key, item) => {
				let $item = $(item);
				let topOffset = $item.offset().top - $fullpage.offset().top;
				let height = $item.height();
				let scrollDistanceNew = scrollDistance + ($siteWrapperScroll.height() / 2);

				if (scrollDistanceNew > topOffset && scrollDistanceNew < (topOffset + height)) {
					let textIndex = $item.data('text-index');

					this.textSliderShowIndex(textIndex);
				}
			});
		};

		this.textSliderShowIndex = (index) => {
			let $slideWrapper = $('.header_image-' + currentIndex, $header);

			$slideWrapper.children().stop().fadeOut(0);
			$slideWrapper.children().filter(':eq(' + index + ')').stop().fadeIn(0);
		};
	}


	$(document).ready(() => {
		cw.scroll = new Scroll();
		cw.scroll.init();
	});

})(jQuery);